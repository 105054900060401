<template>
  <div class="user">
    <div class="row row-equal">
    <div class="row row-equal">
    <div class="flex xs12 xl12">
      <va-button @click="showCreate()">新建账号</va-button>
      </div>
    </div>
      <div class="flex xs12 xl12">
        <va-card title="管理员账号">
          <va-data-table :fields="fields" :data="admins" no-pagination>
            <template slot-scope="props">
              <va-icon
                name="fa fa-circle"
                :color="props.rowData.color"
                size="8px"
              />
            </template>

            <template slot="actions" slot-scope="props">
             <!--
              <va-button
                flat
                small
                color="gray"
                @click="edit(props.rowData)"
                class="ma-0"
                >编辑</va-button>
            -->
              <va-button
                flat
                small
                color="danger"
                @click="showRemove(props.rowData)"
                class="ma-0"
                >删除</va-button
              >
            </template>
          </va-data-table>
        </va-card>
      </div>
    </div>

    <div class="row row-equal">
      <div class="flex xs12 xl12">
        <va-card title="员工账号">
          <va-data-table :fields="fields2" :data="users" no-pagination>
            <template slot-scope="props">
              <va-icon
                name="fa fa-circle"
                :color="props.rowData.color"
                size="8px"
              />
            </template>

            <template slot="actions" slot-scope="props">
             <!--
              <va-button
                flat
                small
                color="gray"
                @click="edit2(props.rowData)"
                class="ma-0"
                >编辑</va-button
              >
            -->
              <va-button
                flat
                small
                color="danger"
                @click="showRemove(props.rowData)"
                class="ma-0"
                >删除</va-button
              >
            </template>
          </va-data-table>
        </va-card>
      </div>
    </div>


    <va-modal
      v-model="showDeleteModal"
      size="small"
      title="删除账号"
      message="删除后无法恢复，是否确认删除？"
      okText="确认"
      cancelText="取消"
      @ok="onOk"
      @cancel="onCancel"
    />

    <va-modal
      v-model="showCreateModal"
      size="small"
      title="新建账号"
      okText="确认"
      cancelText="取消"
      @ok="onCreate"
      @cancel="onCancel"
    >
    <div>
    <form>
        <div class="row">
            <div class="flex md12 sm12 xs12">
                <va-input
                    label="用户名"
                    v-model="username"
                    removable
                />
            </div>
        </div>

        <div class="row">
            <div class="flex md12 sm12 xs12">
                <va-input
                    label="姓名"
                    v-model="name"
                    removable
                />
            </div>
        </div>

        <div class="row">
            <div class="flex md12 sm12 xs12">
                <va-input
                    label="联系电话"
                    v-model="telephone"
                    removable
                />
            </div>
        </div>


        <div class="row">
            <div class="flex md12 sm12 xs12">
                <va-input
                  v-model="email"
                  type="email"
                  label="邮箱"
                  removable
                  >
                </va-input>
            </div>
        </div>

        <div class="row">
            <div class="flex md12 sm12 xs12">
                <va-select
                  label="用户类型"
                  v-model="user_role"
                  textBy="description"
                  :options="typeOptions"
                />
            </div>
        </div>


        <div class="row">
            <div class="flex md12 sm12 xs12">
                <va-input
                    label="请输入密码"
                    v-model="password"
                    type="password"
                    removable
                />
            </div>
        </div>

        <div class="row">
            <div class="flex md12 sm12 xs12">
                <va-input
                    label="确认密码"
                    v-model="password2"
                    type="password"
                    removable
                />
            </div>
        </div>

    </form>
    </div>

    </va-modal>
  </div>
</template>

<script>
// import DashboardContributorsListVue from "../dashboard/DashboardContributorsList.vue";
export default {
  data() {
    return {
      users: [
        // {
        //   username: "Ashley",
        //   password: "Mcdaniel",
        //   fullName: "Ashley Mcdaniel",
        //   email: "ashleymcdaniel@nebulean.com",
        //   telephone: "123456",
        // },
      ],
      admins: [],
      toastText: "",
      toastDuration: 2500,
      toastPosition: "top-center",
      isToastFullWidth: false,
      showDeleteModal: false,
      showCreateModal: false,
      deleteuser: "",
      typeOptions: [
        {
          id: 1,
          description: '管理员',
        },
        {
          id: 2,
          description: '员工',
        },
      ],
      user_role: '',
      username:'',
      name:'',
      password:'',
      password2:'',
      email:'',
      telephone:'',
      toastText: '',
      toastDuration: 2500,
      toastPosition: 'top-center',
      isToastFullWidth: false,
    };
  },
  computed: {
    fields() {
      return [
        {
          name: "username",
          title: "用户名",
        },
        {
          name: "fullname",
          title: "姓名",
        },
        {
          name: "email",
          title: "邮箱",
        },
        {
          name: "telephone",
          title: "电话",
        },
        {
          name: "__slot:actions",
          dataClass: "text-right",
        },
      ];
    },
    fields2() {
      return [
        {
          name: "username",
          title: "用户名",
        },
        {
          name: "fullname",
          title: "姓名",
        },
        {
          name: "email",
          title: "邮箱",
        },
        {
          name: "telephone",
          title: "电话",
        },
        {
          name: "__slot:actions",
          dataClass: "text-right",
        },
      ];
    },
  },
  mounted() {
    console.log("mounted");
    this.axios
      .get("/v1/data/admins", {
        headers: {
          Authorization: "Bearer " + sessionStorage["access_token"],
        },
      })
      .then((res) => {
        this.admins = res.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.axios
      .get("/v1/data/users", {
        headers: {
          Authorization: "Bearer " + sessionStorage["access_token"],
        },
      })
      .then((res) => {
        this.users = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    onOk() {
      console.log("now start delete action");
      var parsedobj = JSON.parse(JSON.stringify(this.deleteuser));
      //   console.log(parsedobj)
      this.axios
        .post(
          "/v1/user/delete",
          parsedobj,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage["access_token"],
            },
          }
        )
        .then((res) => {
          console.log(res);
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });

      this.deleteuser = "";
    //   
    },
    onCancel() {
      console.log("now cancel delete action");
    //   this.deleteuser = "";
      //   console.log(this.deleteuser)
    },
    onCreate() {
        if(this.username === "" || this.name === "" || this.email === "" || this.telephone=== "" || this.user_role === "" || this.password === "" || this.password2 === "")
        {
            this.toastText="请完整填写表单内容";
            this.showToast(this.toastText, {
            position: this.toastPosition,
            duration: this.toastDuration,
            fullWidth: this.isToastFullWidth,
            });
        }

        if(this.email !== "") {
            var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
            //调用正则验证test()函数
            var isok= reg.test(this.email);
            if(!isok) {
                    this.toastText="邮箱格式错误";
                    this.showToast(this.toastText, {
                    position: this.toastPosition,
                    duration: this.toastDuration,
                    fullWidth: this.isToastFullWidth,
                    });
            }
        }
        if(this.telephone !== "") {
            var reg = /^((13[0-9])|(15[1-3,5-9])|(17[7])|(18[0-9]))\d{8}$/;
            //调用正则验证test()函数
            var isok= reg.test(this.telephone);
            if(!isok) {
                this.toastText="电话格式错误";
                this.showToast(this.toastText, {
                position: this.toastPosition,
                duration: this.toastDuration,
                fullWidth: this.isToastFullWidth,
                });
            }
        }
        if(this.password !== this.password2){
            console.log("buyizhi");
            this.toastText="密码输入不一致";
            this.showToast(this.toastText, {
            position: this.toastPosition,
            duration: this.toastDuration,
            fullWidth: this.isToastFullWidth,
            });
        }
        
        this.axios
            .post("/v1/auth/register", {username: this.username, fullname: this.name, email: this.email, telephone: this.telephone, user_role: this.user_role, password: this.password
            })
            .then((res) => {
                location.reload();
            })
            .catch((error) =>{
                if(error.response.status===409)
                {
                    this.toastText="该用户名已存在";
                    this.showToast(this.toastText, {
                    position: this.toastPosition,
                    duration: this.toastDuration,
                    fullWidth: this.isToastFullWidth,
                    });
                }
            });


        
    },
    // edit(user) {
    //   console.log("edit");
    // },
    showRemove(user) {
      this.showDeleteModal = true;
      this.deleteuser = user;
      // console.log(this.deleteuser);
    },
    showCreate() {
      this.showCreateModal = true;
    },

  },
};
</script>
